var $ = require('../internals/export');
var $parseInt = require('../internals/number-parse-int');

// `parseInt` method
// https://tc39.es/ecma262/#sec-parseint-string-radix
$({
  global: true,
  forced: parseInt != $parseInt
}, {
  parseInt: $parseInt
});