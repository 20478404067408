import _Object$keys from "@babel/runtime-corejs3/core-js/object/keys";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js/instance/index-of";
export default function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = _Object$keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (_indexOfInstanceProperty(excluded).call(excluded, key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}