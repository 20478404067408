import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamMembers'
})
export class TeamMembersPipe implements PipeTransform {

  transform(members: any[], idTeam: string, pipeHelper: number): unknown {
    return members.filter(m => m.idTeam == idTeam)
  }

}
